<!--
 * @Descripttion: 我要写信
 v
 * @Author: zyr
 * @Date: 2021-05-29 12:48:06
 * @LastEditors: zyr
 * @LastEditTime: 2021-09-16 17:21:03
-->
<template>
  <div class="write-wrapper">
    <van-form @submit="onSubmit" @failed="onFail" :show-error-message="false" validate-first>
      <!-- 业务类型 -->
      <div class="form-item">
        <div class="form-item-label">
          <span class="require">*</span>
          <span>业务类型</span>
        </div>
        <div class="hack-border hav-pad-32 business" @click="showSelect = true">
          <span :class="{'business-type': true, 'color-type': typeName}">{{typeName || '业务类型'}}</span>
          <img src="../../assets/imgs/arrow.png" alt="" class="arrow"/>
        </div>
      </div>
      <!-- 标题 -->
      <div class="form-item">
        <div class="form-item-label">
          <span class="require">*</span>
          <span class="label">标题</span>
        </div>
        <div class="hack-border hav-pad-32 title">
          <van-field
            v-model="title"
            rows="2"
            :autosize = "{maxHeight: 45, minHeight: 45}"
            label=""
            type="textarea"
            maxlength="20"
            placeholder="请输入标题…"
            show-word-limit
            name = "title"
          />
        </div>
      </div>
      <!-- 正文 -->
      <div class="form-item">
        <div class="form-item-label">
          <span class="require">*</span>
          <span class="label">正文</span>
        </div>
        <div class="hack-border hav-pad-32 title">
          <van-field
            v-model="text"
            rows="6"
            :autosize = "{maxHeight: 150, minHeight: 150}"
            label=""
            type="textarea"
            maxlength="2000"
            placeholder="请输入正文…"
            show-word-limit
            name="content"
          />
        </div>
      </div>
      <!-- 联系方式 -->
      <div class="form-item">
        <div class="form-item-label">
          <span class="require">*</span>
          <span class="label">联系方式</span>
        </div>
        <div class="hack-border hav-pad-32 title">
          <van-field
            v-model="contact" placeholder="请输入联系方式" name="contact" type="digit"
            maxlength="11" autocomplete="off" :error="false"
            :rules="[{
              message: '联系方式格式不正确',
              pattern: /^1[3-9]\d{9}$/
            }]"
          />
        </div>
        <p class="contact-tips">请留下联系方式，如实反映情况，虎门公安会积极处理，尽快回复您</p>
      </div>
      <!-- 性别 -->
      <div class="form-item">
        <div class="form-item-label">
          <span class="require">*</span>
          <span class="label">请选择性别</span>
        </div>
        <div class="hack-border hav-pad-32 title">
          <van-radio-group v-model="gender" direction="horizontal">
            <van-radio name="0">男</van-radio>
            <van-radio name="1">女</van-radio>
          </van-radio-group>
        </div>
      </div>
      <!-- 请输入居住地 -->
      <div class="form-item">
        <div class="form-item-label">
          <span class="require">*</span>
          <span class="label">请输入居住地</span>
        </div>
        <div class="hack-border hav-pad-32 title">
          <van-field v-model="address" placeholder="请输入居住地" name="address" autocomplete="off"/>
        </div>
      </div>
      <!-- 公开与否 -->
      <div class="form-item flex align-center justify-between">
        <div class="form-item-label">
          <!-- <span class="require">*</span> -->
          <span class="label">是否愿意公开</span>
        </div>
        <div class="switch-wrapper">
          <van-field name="ispublic">
            <template #input>
              <van-switch v-model="ispublic" size="20" />
            </template>
          </van-field>
        </div>
      </div>
      <van-button :disabled="isDisable" :color="'#5460FE'" block native-type="submit"
        :style="isDisable ? {background: '#8F92BB', 'border-color': '#8F92BB'} : ''">确定</van-button>
    </van-form>
    <p class="show-tips" @click="show = true">发布小贴士</p>
    <!-- 弹窗小贴士 -->
    <van-popup v-model="show" close-on-popstate>
      <div class="tips-wrapper">
        <img src="../../assets/imgs/warn.png" alt="" class="warn"/>
        <p class="warn-tlt">发布小体贴士</p>
        <p class="warn-desc">鼓励向上、真实的内容，请不要包含以下内容：</p>
        <div class="warn-list">
          <p>1、含有不文明语言</p>
          <p>2、含有网址链接</p>
          <p>3、含有反动、危害国家民众安全、危害领土完整信息</p>
        </div>
        <van-button :color="'#5460FE'" block @click="changeShow">我知道了</van-button>
      </div>
    </van-popup>
    <!-- 控制面板 -->
    <van-action-sheet v-model="showSelect" :actions="actions" @select="onSelect" close-on-popstate/>
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { getType, letter } = API
export default {
  name: 'Write',
  data () {
    return {
      typeName: '',
      type: 0,
      title: '',
      text: '',
      contact: '',
      gender: '0',
      address: '',
      ispublic: false,
      show: false,
      showSelect: false,
      actions: [],
      canClick: true
    }
  },
  computed: {
    isDisable: function () {
      if (this.title && this.text && this.type && this.contact && this.address) return false
      else return true
    }
  },
  methods: {
    onSelect (val) {
      const { name, id } = val
      this.type = id
      this.typeName = name
      this.showSelect = false
    },
    getActions () {
      this.$axios({
        url: getType,
        method: 'get',
        headers: { tkn: this.$store.state.token }
      }).then(({ code, msg, model }) => {
        if (code === 0) this.actions = model
      })
    },
    onFail (info) {
      const { errors: [{ message }] } = info
      this.$toast(message)
    },
    onSubmit (values) {
      if (!this.canClick) return
      this.canClick = false
      values.ispublic = values.ispublic ? 1 : 0
      this.$axios({
        url: letter,
        method: 'post',
        headers: { tkn: this.$store.state.token },
        data: {
          ...values,
          gender: this.gender,
          type: this.type,
          to: this.$store.state.id
        }
      }).then(({ code, msg }) => {
        this.canClick = true
        if (code === 0) {
          this.$toast({
            type: 'success',
            message: '提交成功',
            duration: 1500
          })
          setTimeout(() => {
            this.$router.push(`/home?id=${this.$store.state.id}`)
          }, 1500)
        } else this.$toast(msg)
      })
    },
    changeShow () {
      this.show = false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getActions()
      vm.typeName = ''
      vm.type = 0
      vm.title = ''
      vm.text = ''
      vm.contact = ''
      vm.ispublic = false
      vm.show = false
      vm.gender = '0'
      vm.address = ''
    })
  }
}
</script>
<style lang='less'>
@import '../../assets/less/flex.less';
.van-cell{
  padding: 0
}
.van-field__word-limit{
  color: #8993A8;
  font-size: .px2rem(26px)[@rem];
}
.van-button{
  height: .px2rem(108px)[@rem];
  border-radius: .px2rem(20px)[@rem];
  margin-top: .px2rem(168px)[@rem];
}
.van-button--disabled{
  opacity: 1;
}
.van-button__text{
  font-size: .px2rem(36px)[@rem];
}
.tips-wrapper .van-button{
  height: .px2rem(98px)[@rem];
  border-radius: .px2rem(8px)[@rem];
  margin-top: 0;
}
.tips-wrapper .van-button__text{
  font-size: .px2rem(32px)[@rem];
}
</style>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
.write-wrapper{
  margin: 0 .px2rem(32px)[@rem];
  padding-top: .px2rem(32px)[@rem];
}
.form-item{
  margin-bottom: .px2rem(38px)[@rem];
  font-size: .px2rem(32px)[@rem];
}
.form-item-label{
  color: #101112;
  line-height: .px2rem(72px)[@rem];
}
.hav-pad-32{
  padding: 0 .px2rem(32px)[@rem];
  border-radius: .px2rem(10px)[@rem];
}
.business{
  height: .px2rem(84px)[@rem];
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrow{
  width: .px2rem(42px)[@rem];
  margin-left: .px2rem(30px)[@rem];
  flex-shrink: 0;
}
.business-type{
  color: #C1C3C7;
}
.require{
  color: #F92B2D;
  padding-right: .px2rem(10px)[@rem];
}
.title{
  padding-top: .px2rem(20px)[@rem];
  padding-bottom: .px2rem(20px)[@rem];
}
.tips-wrapper{
  border-radius: .px2rem(16px)[@rem];
  width: .px2rem(640px)[@rem];
  padding: .px2rem(48px)[@rem];
  box-sizing: border-box;
}
.warn{
  width: .px2rem(102px)[@rem];
  display: block;
  margin: auto;
}
.warn-tlt{
  color: #101112;
  font-size: .px2rem(36px)[@rem];
  text-align: center;
  margin: .px2rem(32px)[@rem] 0 .px2rem(16px)[@rem];
}
.warn-desc{
  color: #101112;
  font-size: .px2rem(30px)[@rem];
}
.warn-list{
  color: #8993A8;
  margin: .px2rem(49px)[@rem] 0 .px2rem(88px)[@rem];
  font-size: .px2rem(30px)[@rem];
  line-height: .px2rem(46px)[@rem];
}
.color-type{
  color: #101112;
}
.contact-tips{
  margin-top: .px2rem(16px)[@rem];
  font-size: .px2rem(24px)[@rem];
  color: #8993A8;
}
.switch-wrapper{
  width: .px2rem(88px)[@rem];
}
.show-tips{
  text-align: center;
  color: #5460FE;
  font-size: .px2rem(28px)[@rem];
  text-decoration: underline;
  margin-top: .px2rem(146px)[@rem];
  margin-bottom: .px2rem(66px)[@rem];
}
</style>
